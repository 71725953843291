import React from "react";
import { Link } from "gatsby";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="has-text-centered columns">
            <div className="column">
              <a
                title="linkedin"
                href="https://es.linkedin.com/in/carmensantalo"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <span className="icon">
                  <i
                    className="fab fa-linkedin"
                    style={{
                      fontSize: "2em",
                      paddingBottom: "1em",
                      marginRight: "1em",
                    }}
                  ></i>
                </span>
              </a>
              <a
                title="twitter"
                href="https://twitter.com/carmen_santalo"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <span className="icon">
                  <i
                    className="fab fa-twitter"
                    style={{
                      fontSize: "2em",
                      paddingBottom: "1em",
                    }}
                  ></i>
                </span>
              </a>
            </div>
            <div className="column">© Carmen Santaló</div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
